/* .slider{
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image{
    width: 1000px;
    height: 600px;
    border-radius: 10px;
  }
  
  .right-arrow{
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color:#000;
    z-index: 100;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow{
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color:#000;
    z-index: 100;
    cursor: pointer;
    user-select: none;
  } */
  
  .slide{
  opacity: 0;
  transition-duration: 1s ease;
  /* width: 50vw !important;
  height: 50vh !important; */
  }
  
  .slide-active{
    opacity: 1;
    transition-duration: 3s;
    transform: scale(1.01);
    /* width: 50vw !important; */
    /* height: 50vh !important; */
  }