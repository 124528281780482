@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

/* Styling Slider components - ten I will have to make it in styled components. */

.right-arrow{
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color:#1fb8ff !important;
  /* z-index: 100; */
  cursor: pointer;
  user-select: none;
  /* box-shadow: 0 0 20px 5px #1fb8ff;
  border-radius: 50%;
  border: none !important; */
}

.left-arrow{
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color:#1fb8ff !important;
  /* box-shadow: 0 0 20px 5px #1fb8ff;
  border-radius: 50%;
  border:0rem !important; */
  /* z-index: 100; */
  cursor: pointer;
  user-select: none;
}
