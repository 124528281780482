.form-container {
	/* margin: 100px auto; */
	/* width: 1000px; */
	width: 100%;
	height: 100vh;
	/* box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2); */
	position: relative;
	/* border-radius: 10px; */
	/* height: 600px; */
	display: grid;
	grid-template-columns: 1fr 1fr;
	/* background-color: black; */
}

.form-content-left {
	background: linear-gradient(
		90deg,
		rgb(39, 176, 255) 0%,
		rgb(0, 232, 236) 100%
	);
	/* border-radius: 10px 0 0 10px; */
	position: relative;
}

.form-img {
	width: 80%;
	height: 80%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* @media(max-width: 768px) {

    .form-container{
      width: 50%;
      height: 50%;
      grid-template-columns: 1fr;
    }
  } */

.form-img-2 {
	width: 60%;
	height: 60%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.form-success {
	text-align: center;
	font-size: 24px;
	margin-top: 80px;
	color: #fff;
}

.form-content-right {
	/* border-radius: 0 10px 10px 0; */
	position: relative;
	background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
}

.form {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.form h1 {
	font-size: 2rem;
	text-align: start;
	width: 80%;
	margin-bottom: 1rem;
	color: #fff;
}

.form h2 {
	font-size: 1rem;
	text-align: start;
	width: 80%;
	margin-bottom: 1rem;
	color: #fff;
}

.form-inputs {
	margin-bottom: 0.5rem;
	width: 80%;
}

.form-inputs p {
	font-size: 0.8rem;
	margin-top: 0.5rem;
	color: #f00e0e;
}

.form-textarea {
	width: 100%;
	height: 100px;
	/* display: block; */
	font-size: 0.8rem;
	margin-top: 0.5rem;
	padding-left: 10px;
	/* box-sizing: border-box; */
	border-radius: 2px;
	outline: none;
	border: none;
	text-align: start;

	/* position:static; */
	/* resize: none; */
}

.form-label {
	display: inline-block;
	font-size: 0.8rem;
	margin-bottom: 6px;
	color: #fff;
}

.form-input {
	display: block;
	padding-left: 10px;
	outline: none;
	border-radius: 2px;
	height: 40px;
	width: 100%;
	border: none;
}

.form-input::placeholder {
	color: #595959;
	font-size: 12px;
}

.form-input-btn {
	width: 80%;
	height: 50px;
	margin-top: 10px;
	border-radius: 2px;
	background: linear-gradient(
		90deg,
		rgb(39, 176, 255) 0%,
		rgb(0, 232, 236) 100%
	);
	outline: none;
	border: none;
	color: #fff;
	font-size: 1rem;
}

.form-input-btn:hover {
	cursor: pointer;
	background: linear-gradient(
		90deg,
		rgb(39, 143, 255) 0%,
		rgb(12, 99, 250) 100%
	);
	transition: all 0.4s ease-out;
}

@media (max-width: 768px) {
	.form-container {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
	}

	.form-content-left {
		display: none;
	}

	.form h1 {
		font-size: 1.5rem;
	}

	.form h2 {
		font-size: 0.9rem;
	}

	.form-inputs p {
		font-size: 0.8rem;
		margin-top: 0.5rem;
		color: #f00e0e;
	}

	.form-label {
		font-size: 0.8rem;
	}

	.form-input {
		height: 30px;
		width: 100%;
	}

	.form-input-btn {
		width: 80%;
		height: 30px;
		margin-top: 5px;
		margin-bottom: 5px;
		font-size: 0.8rem;
	}
}

/* @media handheld, (min-width: 650px), (orientation: landscape) {

    .form-container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .form-content-left {
    display: none;
  } 
} */

@media screen and (max-width: 767px), screen and (max-height: 480px) {
	.form-container {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
	}

	.form-content-left {
		display: none;
	}

	.form h1 {
		font-size: 0.8rem;
	}

	.form h2 {
		font-size: 0.5rem;
		display: none;
	}

	.form-inputs p {
		font-size: 0.5rem;
		margin-top: 0.3rem;
		color: #f00e0e;
	}

	.form-label {
		font-size: 0.5rem;
	}

	.form-input {
		height: 20px;
		width: 100%;
	}

	.form-input-btn {
		width: 80%;
		height: 20px;
		margin-top: 5px;
		margin-bottom: 5px;
		font-size: 0.8rem;
	}

	.form-input::placeholder {
		font-size: 0.5rem;
	}

	.form-textarea {
		width: 100%;
		height: 40px;
		font-size: 0.5rem;
		/* margin-top: 5px; */
		padding-left: 10px;
		border-radius: 2px;
		outline: none;
		border: none;
		text-align: start;
	}
}

/* @media screen and (max-width: 768px), 
  screen and (orientation: landscape) {

    .form-container{
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    }

    .form-content-left {
      display: none;
    }
} */

/* @media screen and (max-width: 768px), 
  screen and (max-height: 584px) {
    .form-content-left {
      display: none;
    }
} */

@media screen and (max-height: 584px) {
	/* .form-content-left {
      display: none;
    } */

	/* .form-container{
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    }
    .form-content-right {
      height: 1000px !important;
      display: grid;
      grid-template-columns: 1fr;
    } */
}

@media (max-width: 480px) {
	.form-container {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
	}

	/* ZLE BELOW */
	/* .form-content-right {
      height: 1000px !important;
      display: grid;
      grid-template-columns: 1fr;
    } */

	/* Horizontal orientation of the page */
	/* @media only screen and (orientation: landscape) {
      .form-content-right {
        height: 1000px;
        display: grid;
        grid-template-columns: 1fr;
      }
    } */

	.form {
		padding-top: 20px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
	}

	.form-content-left {
		display: none;
	}

	.form h1 {
		font-size: 1.5rem;
	}

	.form h2 {
		font-size: 0.9rem;
	}

	.form-inputs p {
		font-size: 0.8rem;
		margin-top: 0.5rem;
		color: #f00e0e;
	}

	.form-label {
		font-size: 0.8rem;
	}

	.form-input {
		height: 30px;
		width: 100%;
	}

	.form-input-btn {
		width: 80%;
		height: 30px;
		margin-top: 5px;
		margin-bottom: 5px;
		font-size: 0.8rem;
	}
}
